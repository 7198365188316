import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Building expert tools using the web medium`}</h1>
    <p>{`A good deal of websites on the internet (e.g. say Zappos and Etsy) are designed to get people off of them as quickly as possible, having in the meanwhile deposited as much money as possible. A good deal of others (e.g. Netflix and YouTube) try to maximize time on site and maximize subscription engagement or advertising revenue.`}</p>
    <p>{`Expert tools — online and off — are not like that. They have different dispositions, optimizations, ways of managing attention, historical trajectory and established best practices. They have quantitatively different ratios of users to development costs, and different goals.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/1770265/240664122-d7b079f9-6a57-4484-a711-e04242832c36.jpg",
        "alt": "apollo interior"
      }}></img></p>
    <p>{`The 1969 Apollo command module (above) had an electro-mechanical user interface with dimensions about seven feet wide and three feet tall. It was a major advancement that heralded many innovations, from computing miniaturization to fly-by-wire control systems. The program cost $250 billion in 2023 inflation adjusted dollars; in total, `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/List_of_Apollo_astronauts"
      }}>{`24 astronauts`}</a>{` flew Apollo missions. `}</p>
    <p>{`You might be surprised to discover, in contrast, SpaceX astronauts in 2023 looking at a list labeled "CREW TIMELINE" in Safari on an iPad: `}</p>
    <p><img parentName="p" {...{
        "src": "https://www.shanemielke.com/img/projects/spacex/crew-dragon-displays/discovery8.jpg",
        "alt": "https://www.shanemielke.com/img/projects/spacex/crew-dragon-displays/discovery8.jpg"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/1770265/240434837-4b69589a-5c19-4d52-a2be-1c6352ccd8f0.png",
        "alt": "spacex dragon capsule web ipad"
      }}></img></p>
    <p>{`"We actually run our whole lives off ... a tablet that runs web based software that contains all ... procedures ... we need to run throughout our day ... this is absolutely the hub of everything we do and (it) enables everything we do." `}<em parentName="p">{`— Christina H. Koch, NASA Astronaut `}<a parentName="em" {...{
          "href": "https://www.infoq.com/news/2020/06/javascript-spacex-dragon/"
        }}>{`on her day to day life on the International Space Station`}</a></em></p>
    <p>{`Expert tools mediate the the user and information from decision making environments that involve a large numbers of parameters. They are built to be lived in. `}</p>
    <p><img parentName="p" {...{
        "src": "https://upload.wikimedia.org/wikipedia/commons/d/d3/Airbus-319-cockpit.jpg",
        "alt": "airbus cockpit"
      }}></img></p>
    <p>{`There is no expectation that a spaceship interface is easy to use. The same applies to Photoshop for images and Logic Pro and Ableton Live for sound. Now, Spotify has released `}<a parentName="p" {...{
        "href": "https://www.soundtrap.com/"
      }}>{`Soundtrap, a digital audio workstation (DAW) on the web`}</a>{` which allows multiplayer collaboration, much like Figma, which supplanted Sketch, as Google Docs challenged Microsoft Word. Each of these domains usually require training or at least some experience.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/1770265/240784143-56dcae80-ce5a-40fe-8f46-a29749f8f14a.jpg",
        "alt": "ableton live interface"
      }}></img>{`
`}<em parentName="p">{`Ableton Live, a desktop based DAW`}</em></p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/1770265/241460609-d7e90bde-19ad-4080-877e-0facdc8351ec.png",
        "alt": "spotify daw"
      }}></img>{`
`}<em parentName="p">{`Spotify's Soundtrap, a web based DAW`}</em></p>
    <p>{`Musical instruments are tools. They are designed as an extension of the player. They are a good place to observe how expert tools are an extension of the user, and allied with the user to their goal and purpose, unlike much web design.`}</p>
    <h3>{`USS McCain & Chernobyl`}</h3>
    <p>{`The high-stakes human-machine interface on the bridge of Arleigh Burke-class destroyers offers a contrast to civilian technologies. Less than 100 have been constructed, each costing around $2 billion apiece, with roughly a dozen officers and sailors manning the bridge at a time. `}</p>
    <p>{`A switch away from electro-mechanical to digital displays led to 10 deaths in 2017 on the Arleigh Burke-class USS John S. McCain. `}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/1770265/238160069-b4da6cce-a060-49b0-9ce4-a4e9155468aa.png",
        "alt": "orignal mccain bridge"
      }}></img></p>
    <p><em parentName="p">{`The bridge of the USS John S. McCain, before the switch to the integrated bridge and navigation system (IBNS) via `}<a parentName="em" {...{
          "href": "https://www.navsource.org/archives/05/01056.htm"
        }}>{`navsource`}</a></em></p>
    <p>{`Pro Publica reported `}<a parentName="p" {...{
        "href": "https://features.propublica.org/navy-uss-mccain-crash/navy-installed-touch-screen-steering-ten-sailors-paid-with-their-lives/"
      }}>{`a deep dive into the issues with the touch screen`}</a>{`. `}<a parentName="p" {...{
        "href": "https://adrian3.com/blog/2019/2019-09-28-The-US-Navys-100-million-dollar-checkbox.php"
      }}>{`Another piece`}</a>{` dubbed it "the $100 million dollar checkbox". The collision and deaths were avoidable — while it's possible to blame command, training and lack of experience — the human-machine interface mattered, and failed.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/1770265/238157692-418e5dd3-decf-4254-802e-b458d163c5bd.png",
        "alt": "Propublica article on the USS John McCain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://features.propublica.org/navy-uss-mccain-crash/assets/images/ui-overview-9x7.jpg",
        "alt": "USS John McCain interface"
      }}></img></p>
    <p>{`The US Navy is now `}<a parentName="p" {...{
        "href": "https://news.usni.org/2019/08/09/navy-reverting-ddgs-back-to-physical-throttles-after-fleet-rejects-touchscreen-controls"
      }}>{`switching back`}</a>{`.`}</p>
    <p>{`The 1986 Chernobyl disaster also involved a fast moving, complex information environment with poor feedback mechanisms. While the RBMK reactor type was fundamentally flawed, the overwhelmed, undertrained operators lacked real-time feedback, alarm systems that prioritized certain signals, and clear and intuitive interfaces. There was uncertainty about the state of the system, as demonstrated so brilliantly by the final episode of HBO's series Chernobyl.`}</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/7AaSfysYEEg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <p>{`These two disasters involved many overlapping fields and subfields — of design, information design, sociology of the teams of humans, mechanical and electrical engineering, individual and group psychology and many more. User interfaces which mediate the information and decision making environment in critical domains demand great responsibility. The opportunities are similarly great too: by mediating our relationship with complex information environments, we become a bit superhuman. `}</p>
    <p>{`Here, a scanning electron microscope offers the operator feedback about the elemental makeup of a sample:`}</p>
    <p><img parentName="p" {...{
        "src": "https://www.thermofisher.com/in/en/home/electron-microscopy/products/software-em-3d-vis/elemental-mapping-software/_jcr_content/MainParsys/tabworkflowcontainer/container-bda01c5d-cdc7-4b00-91fd-724122cc2b71/tab/image_9ca1/backgroundimg.img.jpg/1661214612718.jpg",
        "alt": "scanning electron"
      }}></img>{` `}</p>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.thermofisher.com/us/en/home/electron-microscopy/products/software-em-3d-vis/elemental-mapping-software.html"
        }}>{`Phenom elemental mapping software, ThermoFisher scientific`}</a></em>{` `}</p>
    <h3>{`Designing augmentation, designing human in the loop systems`}</h3>
    <p>{`Expert tools mediate the user and their environment by translating and transforming signals and intended actions. They expose an information space for decisionmaking and convert user intention to actions and outcomes. They `}<a parentName="p" {...{
        "href": "https://hackaday.com/2013/09/10/retrotechtacular-the-apollo-guidance-computer/"
      }}>{`augment human perception with computation.`}</a>{`.`}</p>
    <p>{`Let's consider the progression of ground transportation from unmediated to automated: the runner, the F1 racecar in which a driver in the loop uses tools to travel 200mph, and a driverless car which automates the human out of the loop entirely.`}</p>
    <h6>{`UNMEDIATED`}</h6>
    <image src="https://en.wikipedia.org/wiki/Walking#/media/File:Muybridge_human_male_walking_animated.gif" />
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/1770265/240910046-82f7753b-1241-4263-8da4-9b6dfc89d80e.png",
        "alt": "running"
      }}></img></p>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.cs.cmu.edu/~hgeyer/Teaching/R16-899B/Papers/Alexander84AmericanScientist.pdf"
        }}>{`Alexander, 1984`}</a></em></p>
    <h6>{`MEDIATED / AUGMENTED / HUMAN IN THE LOOP SYSTEM`}</h6>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/OX58IkeSl4c" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <h6>{`AUTOMATED / HUMAN OUT OF THE LOOP`}</h6>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/1770265/240921178-2ffb35d3-6c18-459c-ac8f-1aa1c85d6774.png",
        "alt": "driverless car"
      }}></img></p>
    <p>{`What's the design process for the F1 wheel, a human in the loop system? The designers lay out a number of principles and constraints of the system and goals:`}</p>
    <ul>
      <li parentName="ul">{`Design proceeds from first order principle of "retreivability", driver assumed to always have hands on wheel and has to reach with thumbs — we can evaluate the final design from the perspective of "thumb reach"`}</li>
      <li parentName="ul">{`The driver is involved from the first stages of design, considering:`}
        <ul parentName="li">
          <li parentName="ul">{`physical shape`}</li>
          <li parentName="ul">{`button layout`}</li>
          <li parentName="ul">{`"interaction with complex systems onboard"`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The environment is assumed to be high vibration, so robust components are required which prevent accidental actions`}</li>
      <li parentName="ul">{`The driver is assumed to be wearing gloves, all affordances must account for this`}</li>
      <li parentName="ul">{`There are changes in modes, like attacking and defending, and interaction with MGU-K (turbocharging, which uses exhaust to spin a compressor to direct air and fuel into the engine), which are global states that might affect any affordance`}</li>
      <li parentName="ul">{`Each driver's hand is different, so rubber molds are required`}</li>
      <li parentName="ul">{`Customizations might be made for different circuits / courses `}</li>
      <li parentName="ul">{`Features changes are staged on different versions of the wheel`}</li>
    </ul>
    <p>{`The design principles for the F1 wheel conclude with something intangible: the driver must balance their intuition and engineering mindset, and the wheel must faciliate that. Gut and analysis are both essential and an optimal wheel maximizes both without minimizing the other. `}</p>
    <p>{`The wheel looks complicated! Complicated tools require training, but we can measure outcomes in races and winners. There have been less than 800 drivers in F1 history, generating over a billion per year in revenue for the sport. `}</p>
    <h3>{`Pistol shrimp & DSKY`}</h3>
    <h6>{`Multi-objective optimization in biological and human systems`}</h6>
    <p>{`The first websites in history were comprised solely of text, and links: `}<a parentName="p" {...{
        "href": "http://info.cern.ch/hypertext/WWW/History.html"
      }}>{`http://info.cern.ch/hypertext/WWW/History.html`}</a>{`. Inline images, `}<a parentName="p" {...{
        "href": "https://www.zdnet.com/home-and-office/networking/mosaics-birthday-25-years-of-the-modern-web/"
      }}>{`introduced in the Mosaic browser`}</a>{`, were an innovation.`}</p>
    <p>{`When we talk about web design, we don't usually mean patterns rooted in the long tradition of building tools for experts. Until very recently, the hardware and software haven been immature. Historically, most investment has been in ecommerce, and has involved graphic design. This is a problem because the practices are different, but also because the patterns, libraries and infrastructure encode a different "objective function" — that is, concretely: most available infrastructure for building web applications is meant to maximize revenue and standardized branding across multiple devices and formats in the domain of ecommerce.`}</p>
    <p>{`As the medium of the web, connectivity, and device power advance, designers and builders get to be more ambitious. As we see functionality for web GPUs come online and electron apps proliferate, graphic design isn't a sufficient set of practices. Engineers must look further afield as applications become more ambitious. What history and practices can we draw on?`}</p>
    <p>{`There is a growing body of modern work. Take this figure from "Towards Design Principles for Visual Analytics in Operations Contexts":`}</p>
    <p><img parentName="p" {...{
        "src": "https://www.researchgate.net/profile/Scott-Davidoff/publication/324664420/figure/fig3/AS:933261039648768@1599518179104/The-Meridian-system-was-designed-for-operations-engineers-at-the-NASA-Jet-Propulsion.png",
        "alt": "https://www.researchgate.net/publication/324664420_Towards_Design_Principles_for_Visual_Analytics_in_Operations_Contexts"
      }}></img></p>
    <p>{`"The Meridian system was designed for operations engineers at the NASA Jet Propulsion Laboratory. Engineers using the system are tasked with selecting a heading at which to park the rover and an overpass to use for transmitting data. Users of the system (1) enter information about the rover’s location; (2) observe the available overpasses given the provided information; (3) brush on the polar plots to select a range of headings for additional inspection; (4) use the detail panel to select a heading and overpass pair." `}<a parentName="p" {...{
        "href": "https://www.researchgate.net/publication/324664420_Towards_Design_Principles_for_Visual_Analytics_in_Operations_Contexts"
      }}>{`Towards Design Principles for Visual Analytics in Operations Contexts`}</a></p>
    <p>{`It's exciting to consider all we can do with the web now.`}</p>
    <p><em parentName="p">{`The Design of Everyday Things`}</em>{` is a common book recommendation for web designers. It is indeed an excellent foundational text. Here we are concerned with the design of uncommon, rare and specialized tools. We are concerned with the mediation not just between a user and the object in question, but the role that an object plays in the mediation of the user and their environment. Here, engineers are wise to revisit Douglas Englebart's `}<a parentName="p" {...{
        "href": "https://csis.pace.edu/~marchese/CS835/Lec3/DougEnglebart.pdf"
      }}>{`AUGMENTING HUMAN INTELLECT: A Conceptual Framework`}</a>{`. `}<a parentName="p" {...{
        "href": "http://worrydream.com/MagicInk/"
      }}>{`Magic Ink (2006) by Brett Victor`}</a>{` rounds up much of this early philosophy and practice, and anticipates many later developments.`}</p>
    <p>{`In the early history of computing, many systems were expert tools for professionals doing business or engineering tasks. Design was "green fields" and perspectives were broad. `}<a parentName="p" {...{
        "href": "https://history-computer.com/visicalc-of-dan-bricklin-and-bob-frankston-guide/"
      }}>{`Visicalc`}</a>{` stands out as emblemetic of the early history of expert tools in the computational medium: `}</p>
    <p><img parentName="p" {...{
        "src": "https://pbs.twimg.com/profile_banners/7746932/1471969173/1500x500",
        "alt": "visicalc"
      }}></img></p>
    <p>{`Let's turn back to Apollo. Consider all complex operations at work in the design of the DSKY computer:`}</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/ndvmFlg1WmE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <p>{`The following dialogue occurs at the 12m28s mark in the above video:`}</p>
    <p>{`Interviewer: "You talk about the design of this computer being very much like land based computers, except that I think of them as occupying whole bays of equiment occupying entire bays except you've got this squeezed into a little box, how did you do that?"`}</p>
    <p>{`Albert Hopkins, Assistant Director of the Instrumentation Laboratory, said, "Miniaturizing a computer like this requires a judicious choice between many quantities. It's first necessary to minimize the number of circuits you use. It's necessary to minimize the size of the components you use and to package them as tightly as possible. However, this must not be carried too far, as it can endanger the reliability of the computer. A compromise must be sought."`}</p>
    <p>{`In interfaces like these, solving one problem creates another. A design methodology which solves for one goal, then shifts its focus and iterates by solving for another, will pile features on top of features, competing and yelling for space and attention. The final product will be a mess, or define success down to something much simpler that won't accomplish ambitious goals. The task, the environment, and the human must be considered as a whole, in an ongoing and iterative manner. All the time the capabilities of the computing medium itself provide bedrock, since advances in the medium itself are necessary to enable the interface.`}</p>
    <p>{`Unfortunately, doing it right involves solving for multiple goals at the same time — and doing so on the web means doing it with less real estate than seven by three feet, or the entire floor of a nuclear power plant where modules dedicated to tasks can be spread out across entire walls. `}</p>
    <p>{`Since it's hard, and humans need incentives to overcome challenges, sports, war and other domains that have a kind of evolutionary pressure on machines are good places to look for design processes that work.`}</p>
    <p>{`Looking back to the beginning of F1 racing, we can see early generations of design, before the evolutionary design process has taken place (with between 7-20 races per year since 1950).`}</p>
    <p><img parentName="p" {...{
        "src": "https://upload.wikimedia.org/wikipedia/commons/3/36/Alfa_Romeo_Tipo_159_Alfetta.jpg",
        "alt": "alfa romeo"
      }}></img></p>
    <p><em parentName="p">{`Alfa Romeo 158/159 Alfetta`}</em></p>
    <p>{`Living things, in their evolution in their environment, face adverse selection in every generation. The pistol shrimp offers a glimpse into extreme tradeoffs among many parameters. When the pistol shrimp snaps its claw, it creates a loud popping sound and generates a high-speed water jet with enough force to stun or kill small aquatic creatures. This is possible due to a phenomenon known as cavitation — the jet leads to a sharp drop in pressure which causes the formation of a cavitation bubble. When this bubble collapses, it produces light (a phenomenon called sonoluminescence), releases intense heat - for a brief moment, the temperature can reach several thousand Kelvin — comparable to the temperature of the sun — and a sound so loud that it can interfere with underwater communications and sonar.`}</p>
    <p>{`The claw can account for up to half of the shrimp's body weight.`}</p>
    <p><img parentName="p" {...{
        "src": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ed/Synalpheus_pinkfloydi_%28full_res%29_by_Arthur_Anker.jpg/2560px-Synalpheus_pinkfloydi_%28full_res%29_by_Arthur_Anker.jpg",
        "alt": null
      }}></img></p>
    <p>{`A larger claw can create a more powerful bubble jet, but it also weighs more and could slow the shrimp down or make it less agile. Greater sensory capability allows the shrimp to better detect prey and predators, but it also requires more energy to maintain. Reproductive success is a key factor in any species' survival, but producing and caring for offspring requires a significant energy investment. And while a shrimp that uses its energy more efficiently will have an advantage in survival, this might come at a cost to other traits like claw size or sensory capability.`}</p>
    <p>{`At each time step, all factors must be weighed against each other.`}</p>
    <p>{`We can look to evolution for a process in which all parameters are considered against all others each time step and receives feedback about the fitness of the organism for the environment in terms of selection. The design of an "`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Evolved_antenna"
      }}>{`evolved antenna`}</a>{`" directly implements this as a design process — it is perhaps unfortunate that reductionist methods are not amenable to systems involving complex human factors. `}</p>
    <p>{`The BBC documentary from Stewart Brand, How Building's Learn, is complemented by Christopher Alexander's talk in Silicon Valley on patterns in architecture and the evolution of buildings over time. The complete series offers a glimpse into how we might consider buildings living systems that interact with their environment, before and after their design — and, though it is difficult — perhaps during their design as well.`}</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/maTkAcDbrEY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/98LdFA-_zfA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <p>{`Perhaps after a successful living design process, they're a bit alive themselves.`}</p>
    <p>{`The realm of designing expert tools using the web medium is a fertile ground for the synthesis of history, technology, and evolutionary design principles. Building tools that facilitate expert tasks require us to navigate a complex terrain of trade-offs, requiring a profound understanding of the expert user, their task, and their environment. `}</p>
    <p>{`From Apollo's DSKY computer to the new era of web-based expert tools, the role of the engineer as a mediator and innovator is becoming increasingly crucial. The lessons from the past, along with the exciting possibilities of the present, are paving the way for a future where expert tools on the web transcend traditional boundaries and augment human intellect.`}</p>
    <h3>{`Further reading`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      